.import-code-block {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: #1C1C1C;
    &__code {
        margin-left: 12px;
        color: #00BA34;
    }
}