.filter-account-drawer {
  .status {
    display: flex;
    .btn-status {
      padding: 4px 12px;
      border-radius: 40px;
      font-weight: 400;
      font-size: 16px;
      align-items: center;
      background: #f6f6f6;
      padding-bottom: 10px;
      margin-right: 16px;
    }
    //   .ant-btn-primary {
    //     border: 1px solid #00ba34;
    //     background: #fff !important;
    //     box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    //     color: #00ba34 !important;
    //   }
    //   :where(.css-dev-only-do-not-override-w8mnev).ant-btn-primary:not(
    //       :disabled
    //     ):hover {
    //     color: #fff;
    //     background-color: #00ba34;
    //   }
  }

  .filter-address {
    display: flex;
    flex-direction: column;
    gap: 24px
  }

  .filter-address-item {
    display: flex;
    flex-direction: column;
    gap: 8px
  }
}
