.modal-add-device {

  .sku-block {
    margin-bottom: 24px;
  }

  .input-sku-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .input-sku {
    flex: 1
  }

  .not-found-device {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F6F6;
    color: #969696;
    font-size: 14px;
    line-height: 20px;
  }


  .device-info {
    background-color: #F6F6F6;
    display: flex;
    gap: 24px;
    padding: 16px;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 144px;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .device-info-text-block {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }

  .device-info-text-row {
    display: flex;
    gap: 16px;
  }

  .device-info-text-row span:first-child {
    color: #969696;
    font-weight: 600;
    min-width: 140px;
  }

  .device-info-text-row span:last-child {
    font-weight: 600;
    color: #1C1C1C;
  }
}
