.sidebar-landingpage-manager {
  .ant-tabs-tab {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .ant-tabs-tab-btn:hover {
    color: #00ba34 !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00ba34 !important;
  }

  .ant-tabs-ink-bar {
    background: #00ba34 !important;
  }

  .product-description-title {
    color: #585757;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .quill {
    border-radius: 8px;
    position: relative;
    //overflow: hidden;
  }

  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
  }

  .ql-editor {
    padding-top: 68px;
  }

  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .quill-editor-disabled {
    background-color: white;
    cursor: not-allowed;
  }

  .quill-editor-disabled p {
    cursor: not-allowed;
  }
  .product-description-title {
    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 8px;
  }
  .ql-toolbar.ql-snow {
    z-index: 999;
  }
  .ql-link {
    display: none !important;
  }

  .product-price-block {
    border: 1px solid transparent;
    margin-bottom: 24px;
  }

  .product-price-block-error{
    border: 1px solid #ff4d4f;
    overflow: hidden;
    border-radius: 8px;
  }

  .product-price-item{
    display: flex;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid #e8e8e8;
    padding-left: 8px;
    padding-right: 8px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .product-price-left{
      flex: 1;
    }

    .product-price-right{
      flex: 1;
      text-align: right;
    }
  }

  .product-price-header{
    color: var(--Light-Grayscale-300, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
}
