.filter-item {
    background: #F6F6F6;
    border-radius: 40px;
    padding: 1px 12px;
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    margin: 0 16px 16px 0;
}

.filter-item-active {
    background: #fff;
    color: #00BA34;
    padding: 0 11px;
    border: 1px solid #00BA34;
}

.filter-item-image {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 1px 9px;
    height: 64px;
    cursor: pointer;
    margin: 0 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-item-active-image {
    background: #fff;
    color: #00BA34;
    padding: 0 8px;
    height: 62px;
    border: 1px solid #00BA34;
}