.list-sim-page {
  width: 100%;
  height: 100%;

  .search-filter-block {
    display: flex;
    gap: 12px;
    .filter-btn {
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
  }

  .drawer-custom {
    .ant-drawer-header {
      border-bottom: 0;
    }

    .ant-drawer-body {
      padding-top: 0;
    }
  }

}

.detail-sim-page {
  .image-block {
    margin-top: 20px;
    margin-bottom: 20px;

    .image-section {
      padding: 0 24px;
      display: flex;
      justify-content: center;
    }
  }

  .edit-button-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    // border-bottom: 1px solid #E8E8E8;
    padding-bottom: 20px;
  }

  .phone-number-block {
    font-weight: 600;
    font-size: 36px;
    text-align: center;
    margin-bottom: 10px;
  }

  .change-status-block {
    margin-bottom: 20px;
  }
}


.detail-sim-drawer{

  .period-error-msg{
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .package-error-msg{
    margin-left: -24px;
    margin-right: -24px;
    padding: 16px;
    background-color: #FF3B3B1A;
    color: #585757;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  

  @media (max-width: 576px) {
    .ant-drawer-body {
      padding: 0;
    }
    
    .package-error-msg{
      margin-left: 0;
      margin-right: 0;
    }

    .edit-button-block{
      padding-right: 16px;
    }

    .image-block .image-section{
      width: 100%;
      height: 136px;
      padding: 16px 105px;
    }

    .image-section img{
      width: auto;
      height: 104px;
    }

    .phone-number-block{
      color: var(--light-grayscale-100, #1C1C1C);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }
  }
}


.sim-dropdown{
  .ant-dropdown-menu-item{
    display: flex;
    align-items: center;
    min-width: 200px;
    padding: auto 16px;
    height: 52px;
  }

  .btn-dropdown{
    display: flex;
    gap: 8px;
  }
}

.drawer-footer{
  display: flex;
  flex-direction: column;
  gap: 8px;
}