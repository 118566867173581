.statistic-content {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px 20px;
}

.statistic-page {
  width: 100%;

  .autocomplete-items {
    position: absolute;
    min-width: 280px;
    width: 100%;
    border-top: none;
    z-index: 99;
    top: 100%;
    right: 0;
    background-color: white;
    max-height: 400px;
    overflow-y: auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .autocomplete-item {
    padding: 16px 12px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    gap: 8px;
    &.center {
      justify-content: center;
    }
  }

  .autocomplete-items::-webkit-scrollbar {
    border-radius: 0;
    width: 4px;
  }
  .autocomplete-items::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, .1);
  }
  .autocomplete-items::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  

  .autocomplete-item:hover {
    background-color: #e6f7ff;
  }

  .autocomplete-item:last-child {
    border-bottom: none;
  }
  h2 {
    color: var(--Light-Grayscale-100, #1c1c1c);

    /* Heading/H4 - 20 */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 140% */
  }

  .content {
    margin-top: 32px;
    display: flex;
  }

  .content-left {
    border-right: 1px solid #e8e8e8;
    padding-right: 24px;
    min-width: 600px;
    @media (max-width: 1800px) {
      min-width: unset;
    }
  }

  .content-right {
    padding-left: 24px;
    flex-grow: 1;
    overflow-y: auto;
  }

  h3 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .top-ctv-list {
    margin-top: 8px;
  }

  .top-ctv-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid var(--Light-Grayscale-400, #e8e8e8);
    font-weight: 600;
    color: var(--Light-Grayscale-100, #1c1c1c);
    cursor: pointer;
    gap: 16px;
  }

  .top-ctv-index {
    font-size: 20px;
    margin-right: 12px;
  }

  .top-ctv-img {
    margin-right: 12px;
    object-fit: cover;
    border-radius: 50%;
  }

  .ctv-name {
    font-size: 14px;
  }

  .ctv-kv {
    font-size: 12px;
    color: var(--Light-Grayscale-300, #969696);
    font-weight: 400;
  }

  .filter-btn {
    width: 28px;
    height: 28px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Light-Grayscale-400, #e8e8e8);
    background: var(--color-White, #fff);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    margin-left: 16px;
  }

  .search-cont {
    width: 216px;
    height: 28px;
  }

  .search {
    font-size: 14px;
    padding: 0;
    padding-left: 32px;
  }

  .search-icon-cont {
    img {
      width: 16px;
      height: 16px;
    }
  }
}

.filter-statistic {
  .title {
    color: var(--Light-Grayscale-100, #1c1c1c);

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
  }

  .btn-close {
    font-weight: 600;
    margin-right: 16px;
  }

  .filter {
    margin-top: 24px;
  }

  .ant-collapse-header {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .ant-space-gap-col-small {
    row-gap: 16px;
  }

  .picker-date {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.modal-ctv-statistic {
  .legend-list {
    justify-content: space-between;
    margin-top: 24px;
  }

  .legend-item {
    margin-right: 0;
  }

  .chart-container {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  .main-section {
    margin-top: 16px;
  }

  .ctv-row {
    display: flex;
    margin-bottom: 16px;
    color: var(--Light-Grayscale-100, #1c1c1c);
  }

  .ctv-left {
    width: 192px;
    color: var(--Light-Grayscale-300, #969696);
    font-weight: 600;
  }

  .total-ctv {
    margin-top: 16px;
    text-align: center;
  }

  .total-title {
    color: var(--Light-Grayscale-200, #585757);
  }

  .total-value {
    color: #000;
    font-weight: 600;
    font-size: 24px;
  }
}

.legend-list {
  display: flex;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 24px;
  font-size: 12px;
  color: var(--Light-Grayscale-100, #1c1c1c);
  font-weight: 400;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 72px;
  @media (max-width: 1500px) {
    margin-right: 20px;
  }
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}

.legend-value {
  color: #585757;
}

.group-up-page {
  .general-statistic {
    gap: 16px;

    &-item {
      padding: 24px;
      justify-content: flex-start;
      gap: 16px;

      .icon-section {
      }

      .information-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: #585757;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.48px;
          text-transform: uppercase;
        }

        .amount {
          color: #1c1c1c;
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }

  .revenue-block {
    display: flex;
    gap: 16px;

    .revenue-growth-section {
      width: calc(66.67% + 8px);
      background: #fff;

      .filter-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        .title {
          color: #1c1c1c;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }

        .filter-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .legend-section {
        display: flex;
        gap: 48px;
        margin-top: 24px;
        margin-left: 20px;
        .legend-item {
          .green-box {
            width: 24px;
            height: 12px;
            background: #00ba34;
            border-radius: 2px;
          }
          color: #585757;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          gap: 12px;
          align-items: center;
          margin-right: 0;
        }
      }
    }

    .revenue-per-item-section {
      flex: 1;
      background: #fff;

      .filter-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        .title {
          color: #1c1c1c;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }

        .filter-box {
          width: 28px;
          height: 28px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .dropdown-select {
        .ant-dropdown-menu-submenu-expand-icon {
          display: none !important;
        }

        .select-item {
          display: flex;
          justify-content: space-between;
          width: 100%;
          min-width: 200px;

          .text {
            color: #1c1c1c;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .icon {
          }
        }
      }

      .legend-list {
        flex-wrap: wrap;
        gap: 12px;

        .legend-item {
          flex: 0 0 calc(50% - 6px);
          justify-content: center;
        }
      }
    }
  }
}
