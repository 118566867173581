body {
  font-family: "Roboto", sans-serif !important;
  //height: -webkit-fill-available;
}

a {
  display: block;
}

// iframe {
//   display: none !important;
// }

.layout {
  display: flex;
  width: 100%;
}

.right-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  background: #f7f7f7;
  min-width: 100%;
  padding: 36px 24px;
  flex-grow: 1;
  max-width: calc(100vw - 300px);
  min-height: calc(100vh - #{$header-height});
}

.breadcrumb-bc {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1c1c1c;
  margin-bottom: 16px;

  .sub-bc {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #969696;
    margin-top: 4px;
  }

  .show-home {
    font-size: 16px;
    font-weight: 400;
    color: #585757;
  }
}

.main-content {
  background: #ffffff;
  padding: 36px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.main-content-container {
  width: 752px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1c1c1c;
    margin-bottom: 32px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  .section {
    margin-bottom: 24px;
  }
}

.order-info {
  margin-top: 24px;
}

.order-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-side {
    display: flex;
    gap: 18px;
  }

  .right-side {
    display: flex;
    gap: 16px;

    .ant-btn-primary {
      background-color: #e92c2c !important;
    }
  }

  .tag {
    font-size: 16px;
    line-height: 24px;
  }
}

.order-code-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #1c1c1c;
}

.order-code {
  color: #00ba34;
  margin-left: 12px;
}

.shop-code {
  color: #00ba34;
  margin-right: 12px;
}

.tag {
  border-radius: 6px;
  padding: 4px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
}

.tag-confirm {
  background: linear-gradient(0deg,
      rgba(0, 133, 255, 0.1),
      rgba(0, 133, 255, 0.1)),
    #ffffff;
  color: #0085ff;
}

.ant-collapse-header-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #585757;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  align-items: center;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  font-size: 20px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  height: 22px;
}

.main-table {
  thead {

    th,
    td {
      background-color: #fff !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #969696 !important;

      &:before {
        display: none !important;
      }
    }
  }
}

.ant-table-tbody {
  cursor: pointer;
}

.tag-pending,
.tag-topup_missing,
.tag-Đang_thực_hiện,
.tag-Đang_hoàn_tiền,
.tag-Nạp_một_phần {
  cursor: pointer;
  background: linear-gradient(0deg,
      rgba(255, 150, 27, 0.1),
      rgba(255, 150, 27, 0.1)),
    #ffffff;
  color: #f98600;
}

.tag-approved,
.tag-approve,
.tag-release_confirmed,
.tag-registered,
.tag-Thanh_toán_một_phần {
  background: linear-gradient(0deg,
      rgba(0, 133, 255, 0.1),
      rgba(0, 133, 255, 0.1)),
    #ffffff;
  color: #0085ff;
}

.tag-canceled,
.tag-archived,
.tag-soldout,
.tag-draft {
  background: #f7f7f7;
  color: #969696;
}

.tag-error-payment,
.tag-error,
.tag-failed,
.tag-cancel,
.tag-deactive,
.tag-reject,
.tag-suspend,
.tag-rejected,
.tag-suspend,
.tag-inactive,
.tag-cancelled,
.tag-register_cancelled,
.tag-release_cancelled,
.tag-deliver_failed,
.tag-refund_failed,
.tag-not_call_topup_service,
.tag-Hệ_thống_tự_hủy,
.tag-Tác_vụ_thất_bại,
.tag-Hoàn_tiền_thất_bại,
.tag-Thất_bại,
.tag-Thanh_toán_lỗi,
.tag-Nạp_thẻ_lỗi {
  background: linear-gradient(0deg,
      rgba(255, 59, 59, 0.1),
      rgba(255, 59, 59, 0.1)),
    #ffffff;
  color: #e92c2c;
}

.tag-completed,
.tag-delivered,
.tag-active,
.tag-success,
.tag-sync-completed,
.tag-available,
.tag-inventory,
.tag-inventory,
.tag-complete,
.tag-paid,
.tag-refund_success,
.tag-refund,
.tag-show,
.tag-pending_topup,
.tag-success_topup_missing,
.tag-Nạp_thẻ_thành_công,
.tag-Giao_dịch_đã_được_thực_hiện,
.tag-Hoàn_tiền_thành_công,
.tag-Đang_thanh_toán,
.tag-Đã_thanh_toán,
.tag-Nạp_thành_công,
.tag-Đã_yêu_cầu_nạp_thẻ,
.tag-Bù_thành_công,
.tag-Đã_yêu_cầu_hoàn_tiền {
  background: linear-gradient(0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)),
    #ffffff;
  color: #00ba34;
}

.tag-unavailable,
.tag-initialize_failed,
.tag-hide {
  background: #ff3b3b1a;
  color: #e92c2c;
}

.tag-sold,
.tag-exported,
.tag-pending_topup_missing,
.tag-Đang_nạp_bù {
  background: #0085ff1a;
  color: #0085ff;
}

.tag-ordering,
.tag-insured {
  background: #ff961b1a;
  color: #f98600;
}

.ant-modal-title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #1c1c1c !important;
}

.tag-cancel-number {
  border: 1px solid #e92c2c;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  color: #e92c2c;
  cursor: pointer;
}

.tag-recover-number {
  border-radius: 8px;
  border: 1px solid var(--light-green-base, #00ba34);
  background: var(--light-background-primary, #fff);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  color: #00ba34;
}

.tag-fail {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.tag-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.tag-sync {
  border: 1px solid #e8e8e8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  color: #1c1c1c;
  cursor: pointer;
}

.btn-confirm {
  background: #00ba34;
  color: #ffffff;

  &:hover {
    background: #00ba34 !important;
    color: #ffffff !important;
  }
}

.btn-cancel {
  background: #e92c2c;
  color: #ffffff;

  &:hover {
    background: #e92c2c !important;
    color: #ffffff !important;
  }
}

.ant-btn {
  height: 40px !important;
}

.search-cont {
  position: relative;
  width: 509px;
  height: 40px;
}

.search {
  width: 100%;
  height: 100%;
  padding-left: 40px;
}

.main-table {
  margin-top: 16px;
}

.search-icon-cont {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.alert {
  position: fixed;
  right: -300px;
  top: 0;
  background-color: #f44336;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  animation: slide-in 0.5s forwards;
  transition: top 0.5s ease;
}

@keyframes slide-in {
  from {
    transform: translate3d(300px, 0px, 0);
  }

  to {
    transform: translate3d(0px, 0, 0);
  }
}

.alert-comp {
  position: fixed;
  right: 24px;
  top: 42px;
  width: 456px;
  animation: slide-in 0.5s forwards;
  transition: top 0.5s ease;
}

.tag-sync {
  cursor: pointer;
  width: 73px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.custom-alert {
  .ant-notification-notice-message {
    margin-bottom: 0 !important;
  }

  .ant-notification-notice-close {
    top: 18px !important;
  }

  .ant-notification-close-x {
    display: flex !important;
    align-items: center !important;
  }
}

.alert-success {
  background: linear-gradient(0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)),
    #ffffff !important;
}

.tag-wrapper {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.ant-upload-wrapper .ant-upload-drag {
  border: none !important;
}

.ant-pagination .ant-pagination-item-active {
  background: #00ba34;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: none !important;
  color: #fff !important;
}

.icon-table {
  height: 32px;
  width: auto;
}

.drawer-common {
  position: relative;

  .ant-drawer-wrapper-body {
    padding-bottom: 50px;
  }

  .drawer-extra {
    display: flex;
    column-gap: 16px;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-btn-default {
    padding: 0 12px !important;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;

    //&:hover {
    //  color: unset;
    //  border-color: #d9d9d9 !important;
    //}
  }

  .ant-btn-primary {
    background-color: #00ba34 !important;
    color: #fff;
    padding: 0 12px !important;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .order-info-detail {
    margin-top: 20.5px;
  }

  .ant-collapse-header {
    color: #585757;
  }

  .ant-collapse-header {
    padding: 0 !important;
    margin-top: 32px;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ticket-detail-panel {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .ticket-detail-item {
    margin-bottom: 0;
  }

  .ant-collapse-header {
    margin-bottom: 24px;
  }

  .ant-collapse-item {
    border: none !important;
    background: #fff !important;
  }

  .ant-collapse-item:first-child .ant-collapse-header {
    margin-top: 0 !important;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .order-detail-panel,
  .owner-info-detail,
  .ticket-detail-panel,
  .product-detail-panel {
    margin-top: 24px;
  }

  .order-detail-left,
  .owner-info-left,
  .ticket-info-left,
  .ticket-detail-left,
  .product-detail-left {
    width: 176px;
    min-width: 176px;
    padding-left: 24px;
    margin-right: 37px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #969696;
    white-space: nowrap;
  }

  .order-detail-right,
  .owner-info-right,
  .ticket-info-right,
  .ticket-detail-right,
  .product-detail-right {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1c1c1c;
  }

  .owner-info-status {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    gap: 2px;

    &-red {
      color: #E92C2C;
    }

    &-green {
      color: #00BA34;
    }
  }

  .order-detail-item,
  .owner-info-item,
  .ticket-info-item,
  .ticket-detail-item,
  .product-detail-item {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
  }

  .order-shipping-detail {
    margin-top: 20px;
  }

  .ant-collapse-borderless {
    background-color: #fff !important;
  }

  .order-detail-table {
    margin-top: 24px;

    .total-block {
      display: flex;
      justify-content: space-between;
      line-height: 52px;
      background-color: #f6f6f6;
      padding: 0 16px;
      gap: 16px;

      .total-key {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }

  .owner-info-title,
  .titile {
    margin-top: 36px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #585757;
  }

  .avatar,
  .identity,
  .passport,
  .phoisim {
    margin-left: 10px;
  }

  .passport {
    display: inline-block;
  }

  .label {
    margin-top: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #969696;
  }

  .avartar-img,
  .passport-img {
    margin-top: 20px;
  }

  .img-body {
    position: relative;
    display: inline-block;
  }

  .identity-img,
  .phoisim-img {
    display: flex;

    .img-title {
      margin-top: 12px;
      text-align: center;
      color: #585757;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .back-indenity {
      margin-left: 50px;
    }
  }

  .owner-attachments {
    img {
      width: 231px;
      height: 130px;
      object-fit: contain;
    }

    .download-img {
      width: 28px;
      height: 28px;
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
      background: transparent;
    }

    .close-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 4px;
      right: 12px;
      cursor: pointer;
      background: transparent;
    }
  }

  .tag-dropdown {
    cursor: pointer;
    position: relative;
  }

  .dropdown-options {
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    /* Light/Background/Primary */
    background: #ffffff;
    /* Light/Drop Shadow/4 */
    box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1),
      0px 9px 46px rgba(0, 0, 0, 0.06), 0px 24px 38px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .dropdown-option {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding: 16px;
      width: 200px;
      border-bottom: 1px solid #e8e8e8;
      color: #1c1c1c;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }

  .dropdown-select {
    width: 131px;
    //height: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 4px 0;
  }

  .right-btn-block {
    display: flex;
    gap: 16px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ant-tabs-tab {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }

  .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #00ba34 !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00ba34 !important;
  }

  .ant-tabs-ink-bar {
    background: #00ba34 !important;
  }

  .other-section {
    display: flex;
    padding-left: 24px;
  }

  .other-note {
    display: flex;
    flex-direction: column;
    width: 440px;
  }

  .other-note-esim {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .other-esim {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
  }

  .other-note-title,
  .other-esim-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #969696;
    margin-bottom: 4px;
  }

  .wrap-btn {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .esim-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 12px;
    width: 112px;
    height: 112px;

    img {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 1/1;
    }
  }

  .esim-info-action {
    display: flex;
    align-items: center;
    gap: 16px;

    .btn-select-esim {
      color: #00BA34 !important;
      border: 1px solid #00BA34 !important;
      background: #fff !important;
    }

    .btn-upload-esim-img {
      border: 1px solid #E8E8E8 !important;
      background: #fff !important;
      color: #1C1C1C !important;
    }
  }
}

.ant-drawer .ant-drawer-header {
  border-bottom: none;
}

.ant-drawer .ant-drawer-title {
  color: #1c1c1c;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.item-with-status {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  height: 108px;
  padding: 24px;
  cursor: pointer;

  .title-item {
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: #1c1c1c;
  }

  .stt-row {
    margin-top: 10px;
    gap: 50px;
  }

  .stt-box {
    border-radius: 6px;

    span {
      padding: 2px 6px;
      height: 24px;
    }

    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
  }

  .stt-pending {
    @extend .stt-box;
    background: #f7f7f7;
    color: #969696;
  }

  .stt-ready_to_review {
    @extend .stt-box;
    background: #f7f7f7;
    color: #969696;
  }

  .stt-confirmed {
    @extend .stt-box;
    color: #00ba34;
    background: linear-gradient(0deg,
        rgba(0, 186, 52, 0.1),
        rgba(0, 186, 52, 0.1)),
      #ffffff;
  }

  .stt-approved {
    @extend .stt-box;
    color: #00ba34;
    background: linear-gradient(0deg,
        rgba(0, 186, 52, 0.1),
        rgba(0, 186, 52, 0.1)),
      #ffffff;
  }

  .stt-canceled {
    @extend .stt-box;
    background: linear-gradient(0deg,
        rgba(255, 59, 59, 0.1),
        rgba(255, 59, 59, 0.1)),
      #ffffff;
    color: #e92c2c;
  }

  .stt-rejected {
    @extend .stt-box;
    background: linear-gradient(0deg,
        rgba(255, 59, 59, 0.1),
        rgba(255, 59, 59, 0.1)),
      #ffffff;
    color: #e92c2c;
  }

  .calendar-row {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #585757;
    }
  }

  .avatar-row {
    @extend .calendar-row;

    span {
      font-weight: 600;
    }
  }
}

.btn-common {
  background: #00ba34;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 40px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  color: #ffffff;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    color: #ffffff !important;
  }
}

.btn-green:not([disabled]) {
  display: inline-flex;
  align-items: center;
  background-color: #00ba34 !important;
  border-radius: 8px;
  color: #fff !important;
  border-color: #00ba34;
  //padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  //margin-right: 8px;

  &:hover:not([disabled]) {
    background-color: #00ba34 !important;
    opacity: 0.8;
    color: #fff !important;
    border-color: #00ba34;
  }
}

.btn-green-outline:not([disabled]) {
  display: flex;
  align-items: center;
  background-color: #fff !important;
  border-radius: 8px;
  color: #00ba34 !important;
  border-color: #00ba34;
  //padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  //margin-right: 8px;

  &:hover:not([disabled]) {
    background-color: #fff !important;
    opacity: 0.8;
    color: #00ba34 !important;
    border-color: #00ba34;
  }
}

.btn-red:not([disabled]) {
  background-color: #e92c2c !important;
  border-radius: 8px;
  color: #fff !important;
  border-color: #e92c2c;
  //padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;

  &:hover:not([disabled]) {
    background-color: #e92c2c !important;
    opacity: 0.8;
    color: #ffffff !important;
    border-color: #e92c2c;
  }
}

.btn-red-outline:not([disabled]) {
  background-color: #fff !important;
  border-radius: 8px;
  color: #e92c2c !important;
  border-color: #e92c2c;
  //padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;

  &:hover:not([disabled]) {
    background-color: #fff !important;
    opacity: 0.8;
    color: #e92c2c !important;
    border-color: #e92c2c;
  }
}

.btn-no-border {
  border: none !important;
}

.bg-transparent {
  background: transparent !important;
}

.btn-common.cancel {
  background: #e92c2c;
}

.button-change-status {
  .default {
    .ant-select-selector {
      color: #969696;
      font-weight: 500;
      background: transparent;
    }
  }

  .unavailable {
    .ant-select-selector {
      color: #e92c2c;
      background: rgba(233, 44, 44, 0.1);
    }
  }

  .available {
    .ant-select-selector {
      color: #00ba34;
      background: rgba(0, 186, 52, 0.1);
    }
  }

  .sold {
    .ant-select-selector {
      color: #0085ff;
      background: rgba(0, 133, 255, 0.1);
    }
  }

  .ordering {
    .ant-select-selector {
      color: #ff961b;
      background: rgba(255, 150, 27, 0.1);
    }
  }

  .ant-select-selector {
    border: unset !important;
  }
}

.display-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-action-add {
  background-color: #00ba34;
  outline: none;
  display: flex;
  align-items: center;
  height: 40px;

  &:hover {
    background-color: #00ba34 !important;
  }
}

.btn-save {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  background: #00ba34;
  color: #ffffff;
  cursor: pointer;
  border-radius: 8px;
}

.btn-save-icon {
  margin-right: 8px;
}

.btn-deactive {
  border-radius: 8px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #e92c2c;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #e92c2c;
  margin-right: 24px;
  background-color: #fff;
  cursor: pointer;
}

.btn-active {
  border-radius: 8px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  align-items: center;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #00ba34;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #00ba34;
  margin-right: 24px;
  background-color: #fff;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.button-ask-active {
  @extend .display-center;
  padding: 4px 12px;
  border: 1px solid #0085ff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  color: #0085ff;
  font-weight: 600;
}

.button-save {
  @extend .display-center;
  padding: 4px 12px;
  background: #00ba34;
  color: white;
}

.button-edit {
  @extend .display-center;
  width: 38px;
}

.button-delete {
  @extend .display-center;
  padding: 4px 8px;
}

.account-avatar {
  background-color: #f6f6f6;
}

.image-component {
  min-height: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-comp-main {
  max-height: 262px;
  max-width: 100%;
}

.choose-image-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drag-file-comp {
  margin-top: 10px;
  margin-bottom: 24px;
  position: relative;
}

.drag-file {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: #f7f7f7;
  border: 1px dashed #e8e8e8;
  border-radius: 8px;
  min-height: 288px;
}

.btn-select-file {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1c1c;
  margin-right: 16px;
}

.text-drop {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585757;

  img {
    margin-right: 8px;
  }
}

.ant-upload-wrapper .ant-upload-drag {
  height: 104px !important;
}

.ant-btn {
  &:focus {
    outline: none;
  }
}

.reject-info {
  width: 100%;
}

.btn-action-edit {
  border-color: #e8e8e8;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.btn-action-delete {
  border-color: #e92c2c;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.btn-action-remove {
  border-radius: 8px;
  color: #e92c2c;
  border: 1px solid #e92c2c;
  font-weight: 600;
  font-size: 14px;
  padding: 0 8px !important;
}

.btn-action-category {
  background-color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  color: #1c1c1c;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;

  &:hover {
    background-color: #00ba34 !important;
  }
}

.ant-switch.ant-switch-checked {
  background: #00ba34 !important;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #00ba34;
  border-color: transparent;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00ba34;
  border-color: #00ba34;
}

.upload-cccd {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.highlight-row {
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.highlight-div {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #585757;
  background: #f6f6f6;
  border-radius: 12px;

  .highlight-row {
    &:last-child {
      border-bottom: none;
    }
  }
}

.highlight-info {
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  /* 160% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 4px;
  margin-left: 12px;
  border-radius: 6px;
  background: #e8e8e8;
}

.highlight-final-price {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

//Form
.form-divide {
  display: flex;
  column-gap: 24px;
}

.child-form {
  flex: 1;
}

//input {
//  height: 40px;
//}
.account-reason {
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  background: var(--light-background-secondary, #f6f6f6);

  .account-reason-title {
    background: var(--light-background-secondary, #f6f6f6);
    color: #585757;
    font-size: 16px;
    font-weight: 600;

    .account-reason-content {
      margin-left: 8px;
    }
  }
}

.modal-reason {
  margin-top: 16px;
  margin-bottom: 16px;
}

.btn-inport-export {
  background-color: #ffffff;
  outline: none;
  display: flex;
  align-items: center;
  height: 40px;
  color: #1c1c1c;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: #ffffff !important;
    border-color: #1c1c1c !important;
    color: #1c1c1c !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-filter-block {
  display: flex;
  gap: 12px;

  .filter-btn {
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }
}

.btn-full-width {
  width: 100%;
  justify-content: center;
}

//Sim list

.sim-list-mobile {
  margin-top: 16px;

  .sim-item {
    display: flex;
    height: 108px;
    padding: 16px 16px 16px 0;
    border-bottom: 1px solid #e8e8e8;
  }

  .provider-img {
    width: 76px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 8px;
    }
  }

  .sim-info {
    flex: 1;
    padding-left: 16px;
  }

  .sim-number {
    color: var(--light-grayscale-200, #585757);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    height: 24px;
  }

  .sim-package {
    color: var(--light-grayscale-300, #969696);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    height: 20px;
    margin-top: 4px;
  }

  .sim-price {
    color: var(--light-grayscale-200, #585757);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    height: 20px;
    margin-top: 10px;
  }

  .sim-status {
    flex-shrink: 0;
  }
}

.font-semibold {
  font-weight: 600 !important;
}

@media (max-width: 1023px) {
  .main {
    padding: 0;
  }

  .breadcrumb-bc {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 576px) {
  .main {
    background: #fff;
  }

  .breadcrumb-bc {
    padding: 16px;
    margin-bottom: 0;
  }

  .sub-bc {
    display: none;
  }

  .main-content {
    padding: 0;
  }

  .main-content-container h1 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .main-content-container h2 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    margin-bottom: 8px;
  }

  .main-content-container p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }

  .homepage {
    padding: 24px;
  }

  .list-sim-page {
    padding: 16px;
  }

  .sim-list-mobile {
    .sim-item {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-drawer {
    .ant-drawer-title {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .form-divide {
    flex-direction: column;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .image-component,
  .drag-file {
    min-height: 130px;
  }

  .image-comp-main {
    max-height: 130px !important;
  }
}

.sim-pagination {
  margin-top: 20px;
  text-align: center;
}

.ant-drawer-body {
  overscroll-behavior: contain;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-container {
  // max-width: calc(100vw - 454px);

  .ant-table-content {
    table {
      width: 100% !important;
      min-width: unset !important;
    }
  }
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}

.table-container {
  margin-top: 24px;
}

.error-icon {
  margin-left: 5px;
}

.tag-ready {
  background: linear-gradient(0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)),
    #ffffff;
  border-radius: 6px;
  padding: 2px 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #00ba34;
}

.sticky-grid__header {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 99999;
}

.sticky-grid__header__base {
  z-index: 99999;
  background: #fff;
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  line-height: 20px;
  color: #969696 !important;
}

.sticky-grid__header__scrollable {
  position: absolute;
}

.sticky-grid__header__scrollable__column {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  line-height: 20px;
  color: #969696 !important;
  z-index: 999;
}

.sticky-grid__sticky-columns__container {
  position: sticky;
  left: 0;
  z-index: 9999;
  background: #fff;
  width: min-content;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
}

.sticky-grid__sticky-columns__row {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f0f0f0;

  b {
    font-weight: 600;
  }
}

.sticky-grid__data__container {
  position: absolute;
}

.sticky-grid__data__column {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  border-bottom: 1px solid #f0f0f0;
}

.tooltip-info {
  position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  left: -75px;
  bottom: 20px;

  z-index: 99999;

  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.08));
  padding: 8px;

  .arrow {
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      vertical-align: middle;
      border-top: 4px solid #f7f7f7;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      margin-left: 74px;
      position: absolute;
    }
  }
}

.tooltip-content {
  // width: 148px;
  width: max-content;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #585757;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 8px;
}

.btn-reup-file {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  color: #1c1c1c;
}

.highlight-bug {
  background: linear-gradient(0deg,
      rgba(255, 150, 27, 0.1),
      rgba(255, 150, 27, 0.1)),
    #ffffff !important;
}

.highlight-fail {
  background: linear-gradient(0deg,
      rgba(255, 59, 59, 0.1),
      rgba(255, 59, 59, 0.1)),
    #ffffff !important;
}

.highlight-duplicate {
  background: linear-gradient(0deg,
      rgba(0, 133, 255, 0.1),
      rgba(0, 133, 255, 0.1)),
    #ffffff !important;
}

.text-red {
  color: #e92c2c !important;
}

.text-green {
  color: #00ba34 !important;
}

.text-blue {
  color: #0085ff !important;
}

.text-gray {
  color: #585757 !important;
}

.input-currency-suffix {
  position: relative;
}

.input-currency-suffix:after {
  position: absolute;
  content: "VNĐ";
  right: 12px;
  transform: translateY(-50%);
  top: 50%;
  // line-height: 38px;
  cursor: text;
}

.price-filter {
  margin-bottom: 16px;

  .input-currency-suffix:after {
    content: ".VNĐ";
    color: #969696;
  }
}

.input-period-day-suffix:after {
  position: absolute;
  content: "ngày";
  right: 12px;
  transform: translateY(-50%);
  top: 50%;
  // line-height: 38px;
  cursor: text;
}

.custom-chart {
  position: relative;
}

.custom-chart canvas {
  border-left: none;
  /* Loại bỏ border left của canvas */
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.total-order-block {
  background: #e8e8e8;
  line-height: 36px;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #585757;

  .title {
    flex: 1;
    text-align: center;
  }

  .amount {
    width: 200px;
    padding-right: 80px;
    text-align: right;
    font-weight: 600;
  }
}

.ant-drawer-body::-webkit-scrollbar {
  border-radius: 0;
  width: 4px;
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(22, 24, 35, 0.06);
}

.ant-drawer-body::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

// Custom checkbox color
.ant-checkbox-checked .ant-checkbox-input:not(disabled)+.ant-checkbox-inner {
  background-color: #00ba34 !important;
  border-color: #00ba34 !important;
  // outline: #00ba34 !important;
}

.ant-checkbox-checked .ant-checkbox-input:not(disabled)+.ant-checkbox-inner:hover {
  background-color: #00ba34 !important;
  border-color: #00ba34 !important;
  // outline: #00ba34 !important;
}

.ant-checkbox-checked:after {
  border-color: #00ba34 !important;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #00ba34 !important;
}

// Checkbox partial
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #00ba34 !important;
}

.detail-modal-btn-suspend {
  background-color: #e92c2c;
  border: 1px solid #e92c2c;
  color: #ffffff;
}

.detail-modal-btn-suspend:hover {
  background-color: #e92c2c !important;
  border: 1px solid #e92c2c !important;
  color: #ffffff !important;
  opacity: 0.8;
}

.detail-modal-btn-active {
  background-color: #ffffff;
  border: 1px solid #00ba34;
  color: #00ba34;
}

.detail-modal-btn-active:hover {
  background-color: #ffffff;
  border: 1px solid #00ba34 !important;
  color: #00ba34 !important;
  opacity: 0.8;
}

.detail-modal-btn {
  padding: 8px 12px !important;
  font-weight: 600;
}

.reason {
  padding: 16px;
  border-radius: 8px;
  background: #f6f6f6;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #585757;
  margin-top: 24px;
}

.account-fullname {
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.account-status {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 20px;
}

.btn-download-template {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: #fff;
  color: #1c1c1c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 12px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.pagination-al {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.stt-pending {
  @extend .stt-box;
  background: #f7f7f7;
  color: #969696;
}

.stt-ready_to_review {
  @extend .stt-box;
  background: #f7f7f7;
  color: #969696;
}

.stt-confirmed {
  @extend .stt-box;
  color: #00ba34;
  background: linear-gradient(0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)),
    #ffffff;
}

.stt-approved {
  @extend .stt-box;
  color: #00ba34;
  background: linear-gradient(0deg,
      rgba(0, 186, 52, 0.1),
      rgba(0, 186, 52, 0.1)),
    #ffffff;
}

.stt-canceled {
  @extend .stt-box;
  background: linear-gradient(0deg,
      rgba(255, 59, 59, 0.1),
      rgba(255, 59, 59, 0.1)),
    #ffffff;
  color: #e92c2c;
}

.stt-rejected {
  @extend .stt-box;
  background: linear-gradient(0deg,
      rgba(255, 59, 59, 0.1),
      rgba(255, 59, 59, 0.1)),
    #ffffff;
  color: #e92c2c;
}

.title-item {
  font-weight: 600;
  line-height: 28px;
  font-size: 20px;
  color: #1c1c1c;
}

.stt-row {
  margin-top: 10px;
  gap: 50px;
}

.stt-box {
  border-radius: 6px;

  span {
    padding: 2px 6px;
    height: 24px;
  }

  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
}

.statistic-section {
  background: #ffffff;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  padding-right: 150px;
  justify-content: space-between;

  .statistic-item {
    .item-key {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #969696;
    }

    .item-value {
      color: #1c1c1c;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.list-section {
  padding: 36px;
  background: #ffffff;

  .filter-btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    padding: 0px 12px;

    p {
      margin-left: 8px !important;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

// Custom tab card
.page-tab-card {
  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
    border: none;
    background: rgba(0, 0, 0, 0);
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
    background: #fff;

    /* Heading/H5 - 16 */
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    /* 150% */
  }

  .ant-tabs .ant-tabs-tab-btn {
    color: var(--light-grayscale-100, #1c1c1c);
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--light-grayscale-100, #1c1c1c);
  }

  .ant-tabs-top>.ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs .ant-tabs-content-holder {
    padding: 16px;
    background: #fff;
  }
}

// Point history
.point-history {
  .point-history-action {
    display: flex;
    justify-content: flex-end;
    height: 40px;
    margin-bottom: 16px;
  }

  .point-history-item-wrapper {
    display: flex;
    padding: 2px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: var(--Light-Grayscale-400, #e8e8e8);
  }

  .point-history-action-item {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    color: var(--Light-Grayscale-200, #585757);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    height: 100%;
    cursor: pointer;
    border: none;
    background: transparent;
    outline: none;
  }

  .point-history-action-item.active {
    background: #fff;
    color: var(--Light-Grayscale-100, #1c1c1c);
    font-weight: 600;
    border-radius: 8px;
  }
}

.bonus-point-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 24px 24px;
  border-radius: 12px;
  border: 1px solid var(--Light-Grayscale-400, #e8e8e8);
}

.bonus-point-text {
  display: flex;
  align-items: center;
  gap: 16px;
}

.wallet-icon-wrapper {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-radius: 100px;
  background: var(--Light-Background-Red,
      linear-gradient(0deg,
        rgba(255, 59, 59, 0.1) 0%,
        rgba(255, 59, 59, 0.1) 100%),
      #fff);
}

.total-point-title {
  color: var(--Light-Grayscale-200, #585757);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 160% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.total-point-value {
  color: var(--Light-Grayscale-100, #1c1c1c);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
}

.star-icon {
  background: var(--Light-Background-Blue,
      linear-gradient(0deg,
        rgba(0, 133, 255, 0.1) 0%,
        rgba(0, 133, 255, 0.1) 100%),
      #fff);
}

.update-reward-point-modal {
  .point-edit-div {
    background-color: #f6f6f6;
    color: #1c1c1c;
    margin: 36px 0;
    border-radius: 10px;

    .point-edit-block {
      &:first-child {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .point-edit-block {
    display: flex;
    justify-content: space-between;
    line-height: 52px;
    margin: 0 16px;

    .key-text {
      font-size: 16px;
      font-weight: 400;
    }

    .value-text {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

// Custom radio color
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #00ba34;
  background-color: #00ba34;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #00ba34;
}

.ant-radio-wrapper:hover .ant-radio-inner::after {
  border-color: #00ba34;
}

.ant-radio-wrapper .ant-radio-checked::after {
  border-color: #00ba34;
}

.ant-wave {
  color: #00ba34;
}

.btn-download-all {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: #fff;
  color: #1c1c1c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

//End custom radio color

.comparing-div {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-comparing {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 24px;
}

.compare-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: var(--Light-Grayscale-100, #1c1c1c);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0;

    span {
      color: var(--Light-Green-Base, #00ba34);
    }
  }
}

.compare-header-right {
  display: flex;
  align-items: center;
}

.compare-toggle {
  display: flex;
  align-items: center;
  color: var(--Light-Grayscale-200, #585757);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  margin-right: 24px;

  span {
    margin-left: 8px;
  }
}

.production-row {
  background: var(--Light-Background-Green,
      linear-gradient(0deg, rgba(0, 186, 52, 0.1) 0%, rgba(0, 186, 52, 0.1) 100%),
      #fff);
}

.compare-table {

  th,
  td {
    white-space: nowrap;
  }

  .footer-compare-modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  .close-compare {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    border: none;
    box-shadow: none;
  }
}

.btn-up-all {
  width: 140px;
  position: relative;
  background: #00ba34;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
    0px 1px 1px rgba(0, 0, 0, 0.08);
  color: #ffffff;

  &.disable {
    background-color: #00ba3466;
    cursor: not-allowed;
  }

  border: 0;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: -1px;
  width: 0%;
  height: 100%;
  background-color: #00ba34;
  transition: width 0.3s ease-in-out;
  border-radius: 8px 0 0 8px;
  z-index: 0;
  border-left: 1px solid #fff;
}

.progress-text {
  position: relative;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-table-cell {
  white-space: nowrap;
}

.gap-2 {
  gap: 8px;
}

.custom-video-modal {
  .ant-modal-content {
    padding: 0 !important;
    background-color: black;
    min-width: 600px;

    .ant-modal-close {
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      color: white;
    }

    .download-button {
      display: none !important;
      /* Hide the download button */
    }
  }
}

.img-body {
  img {
    width: 231px;
    height: 130px;
    object-fit: contain;
    background-color: rgba(233, 233, 233, 1);
  }

  .download-img {
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
    background: transparent;
  }

  .close-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    right: 12px;
    cursor: pointer;
    background: transparent;
  }
}

.all-type-upload {
  .ant-upload-list-item-container {
    width: 231px !important;
    height: 130px !important;
    margin-block: 0 16px !important;
    // margin-inline: 0 16px !important;
  }

  .ant-upload-select {
    width: 231px !important;
    height: 130px !important;
  }
}

.document-viewer {
  width: 231px;
  height: 130px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: rgb(232, 232, 232);
  border-radius: 10px;
  padding: 0px 12px;
  gap: 20px;
  position: relative;

  .document-icon {
    text-align: center;
  }

  .document-name {
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
  }

  .document-download {
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    cursor: pointer;
    background: transparent;
  }
}

.ant-dropdown-menu-submenu-expand-icon {
  display: none !important;
}

.refund-modal-text {
  color: "#585757";
  line-height: 24px;

  b {
    font-weight: 600;
    margin-left: 8px;
  }
}

.modal-consumer-account {
  .ant-modal-footer {
    display: flex;
    justify-content: end;
  }
}

.btn-green-custom {
  border-radius: 8px;
  background: #00ba34;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  color: #fff;
}

.update-esim-modal {
  padding-top: 32px;

  .drag-file {
    height: 72px;
    min-height: 0;
    min-height: auto;
    padding: initial;
  }
}

.esim-img {
  width: 112px;
  height: 112px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    position: relative;
    z-index: 1;
  }

  .uploadable {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    border-radius: 8px;
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%);
  }

  .btn-upload {
    z-index: 3;

    position: absolute;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    background: #fff;
    color: #1c1c1c;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding: 4px 8px;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.list-product-table {
  margin-top: 24px;
}

.btn-upload-esim-img {
  border: 1px solid #E8E8E8 !important;
  background: #fff !important;
  color: #1C1C1C !important;
  padding: 0 12px !important;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 0;
  border-radius: 6px;
  gap: 8px;
  cursor: pointer
}

.btn-upload-esim-img.disabled {
  opacity: 0.5;
}

.ant-input-disabled,
.ant-picker-disabled,
.ant-input[disabled],
.ant-input-number-disabled
 {
  background-color: white !important;
  cursor: not-allowed;
  color: #585757 !important;
}

.ant-select-disabled {
  .ant-select-selector {
    background-color: white !important;
  }
  color: #585757 !important;
  .ant-select-selection-item {
    color: #585757 !important;
    cursor: not-allowed !important;
  }
  .ant-select-selection-item-content {
    color: #585757 !important;
    cursor: not-allowed !important;
  }
}