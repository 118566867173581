.preview-block {
    .thumnail-img {
        width: 100%;
        margin-bottom: 20px;
        border-radius: 20px;
        aspect-ratio: 2/1;
        object-fit: cover;
    }

    .name-and-tags {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin: 30px 0;

        .name-section {
            display: flex;
            gap: 12px;

            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            .information {
                display: flex;
                flex-direction: column;

                .name {
                    font-size: 16px;
                    font-weight: 600;
                    color: #1C1C1C;
                }

                .published-date {
                    font-size: 14px;
                    font-weight: 400;
                    color: #969696;
                }
            }
        }

        .tags-section {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            flex: 1;
            flex-wrap: wrap;

            .tag-item {
                background: #F6F6F6;
                padding: 0 14px;
                color: #585757;
                font-size: 20px;
                font-weight: 400;
                border-radius: 40px;
                line-height: 40px;
            }
        }
    }
}
