.homepage{
 .main-content{
  .main-content-container {
    width: 752px;
  
    h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #1c1c1c;
      margin-bottom: 32px;
    }
  
    h2 {
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 16px;
    }
  
    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
    }
    span{
      color: red;
    }
  
    .section {
      margin-bottom: 24px;
    }
  }
 }

  @media (max-width: 1023px) {
    .breadcrumb-bc{
      display: none;
    }
  }
}
