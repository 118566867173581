.manage-bonus-point-page {}

.update-reward-point-modal {
  .point-after-edit-block {
    background-color: #F6F6F6;
    display: flex;
    justify-content: space-between;
    line-height: 52px;
    color: #1c1c1c;
    padding: 0 16px;
    margin: 36px 0;
    border-radius: 10px;

    .key-text {
      font-size: 16px;
      font-weight: 400;
    }

    .value-text {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.detail-request-reward-point-modal {
  display: flex;
  padding: 24px 0;
  gap: 40px;

  .left-side {
    img {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
  }

  .right-side {
    flex: 1;

    .item-infor {
      margin-bottom: 12px;
      display: flex;
      .infor-key {
        color: #969696;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        width: 170px;
      }

      .infor-value {
        color: #1C1C1C;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .button-block {
      margin-top: 40px;
      text-align: right;
      button {
        margin-left: 20px;
      }
    }
  }
}

.bonus-point-drawer {
  .bonus-point-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px 24px 24px;
    border-radius: 12px;
    border: 1px solid var(--Light-Grayscale-400, #e8e8e8);
  }

  .bonus-point-text {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .wallet-icon-wrapper {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-radius: 100px;
    background: var(--Light-Background-Red,
        linear-gradient(0deg,
          rgba(255, 59, 59, 0.1) 0%,
          rgba(255, 59, 59, 0.1) 100%),
        #fff);
  }

  .total-point-title {
    color: var(--Light-Grayscale-200, #585757);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .total-point-value {
    color: var(--Light-Grayscale-100, #1c1c1c);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
  }
}