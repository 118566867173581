.landing-page-manager {
  width: 100%;
  height: 100%;
  margin-top: 24px;

  .sim-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .sim-item-left {
    padding: 10px 32px;

    img {
      width: 171px;
    }

    margin-right: 32px;
  }

  .sim-item-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px 0;
  }

  .sim-item-title {
    display: flex;
    margin-bottom: 8px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #1c1c1c;
    margin-right: 12px;
  }

  .sim-tag {
    border-radius: 6px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .tag-active {
    background: #00ba341a;
    color: #00ba34;
  }

  .text-expire {
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    color: #585757;
    margin-bottom: 16px;
    div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      font-size: 14px !important;
    }
  }

  .discount-price {
    font-weight: 600;
    line-height: 28px;
    font-size: 20px;
    color: #E92C2C;
  }

  .original-price {
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    color: #585757;
    margin-left: 8px;
    text-decoration: line-through;
  }

  .sim-tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sidebar-landingpage-manager {
  .function-block {
    display: flex;
    justify-content: flex-end;
  }


  .btn-deactive{
    margin-right: 16px;
  }

  .btn-options{
    cursor: pointer;
    border: 1px solid #E8E8E8;
    background: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    &:focus {
      outline: none;
    }
  }

  .options-cont {
    position: relative;
  }

  .dropbox {
    position: absolute;
    top: 45px;
    right: 0;
    padding: 16px 0px;
    width: 200px;
    box-shadow: 0px 24px 38px 0px rgba(0, 0, 0, 0.04), 0px 9px 46px 0px rgba(0, 0, 0, 0.06), 0px 11px 15px 0px rgba(0, 0, 0, 0.10);
    background-color: #fff;
    border-radius: 8px;
    z-index: 99999;
  }

  .dropbox-item {
    padding: 14px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    font-weight: 400;
    line-height: 20px;
    font-size: 14px;
    color: #1C1C1C;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:last-child {
      border-bottom: none
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;

    }
  }
  .dropbox-item-delete {
    color: #E92C2C;

  }

  .sim-tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


@import "sidebarLandingpage";
