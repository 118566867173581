.drawer-partner-account {

    .wrap-btn {
        display: flex;
        gap: 16px;
      }

    .action-block {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 24px;
    }

    .action-filter{
        flex-shrink: 0;
        margin-bottom: 0;
        height: 40px;
        display: flex;
        gap: 4px;
        // align-items: center;
        cursor: pointer;
        padding: 8px 12px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
    }

    .action-search{
        border: 1px solid #e8e8e8;
    }

    .commission-header{
        margin-bottom: 16px;
        color: #969696;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }


    .commission-item{
        display: flex;
        align-items: baseline;
        gap: 24px;
    }

    .commission-field{
        flex: 1;
    }

    .loading-block{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.drawer-seller-account{
    .seller-username{
        .ant-input-prefix{
            margin-right: 0;
            color: #bebebe;
        }
    }

}



// Dropdown filter status account
.filter-dropdown{

    .ant-dropdown-menu-item-selected{
        background-color: #fff !important;
        color: var(--light-grayscale-100, #1C1C1C) !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        img{
            visibility: visible !important;
        }
    }

    .ant-dropdown-menu-item{
        flex-direction: row-reverse;
        height: 40px;

        img{
            visibility: hidden;
            margin-right: 0!important;
        }

        &:hover {
            background: #f6f6f6 !important;
        }
    }
}
