.internal-account-page {
  // .tag-pending {
  //    cursor: pointer;
  //    background: linear-gradient(
  //          0deg,
  //          rgba(255, 255, 255, 1),
  //          rgba(255, 59, 59, 0.1)
  //       ),
  //       #ffffff;
  //    color: #e92c2c;
  // }
  // .tag-approved,
  // .tag-approve {
  //    background: linear-gradient(
  //          0deg,
  //          rgba(255, 255, 255, 1),
  //          rgba(0, 186, 52, 0.1)
  //       ),
  //       #ffffff;
  //    color: #00ba34;
  // }

  .filter-btn {
     display: flex;
     align-items: center;
     border-radius: 8px;
     background-color: #fff;
     border: 1px solid #e8e8e8;
     padding: 0px 12px;
     p {
        margin-left: 8px !important;
        margin: 0;
        font-weight: 600;
        font-size: 16px;
     }
  }
  .btn-options {
     cursor: pointer;
     border: 1px solid #e8e8e8;
     background: #f6f6f6;
     display: flex;
     justify-content: center;
     align-items: center;
     width: 40px;
     height: 40px;
     border-radius: 8px;
     box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
     &:focus {
        outline: none;
     }
  }
}

.account-manage-drawer {
   .detail-modal-btn-suspend {
      background-color: #E92C2C;
      border: 1px solid #E92C2C;
      color: #ffffff;
   }
   .detail-modal-btn-suspend:hover{
      background-color: #E92C2C !important;
      border: 1px solid #E92C2C !important;
      color: #ffffff !important;
      opacity: 0.8;
   }

   .detail-modal-btn-active {
      background-color: #ffffff;
      border: 1px solid #00BA34;
      color: #00BA34;
   }

   .detail-modal-btn-active:hover{
      background-color: #ffffff;
      border: 1px solid #00BA34 !important;
      color: #00BA34 !important;
      opacity: 0.8;
   }

   .detail-modal-btn {
      padding: 8px 12px !important;
      font-weight: 600;
    }
  .drag-file-comp {
     margin-top: 4px;
  }
  .drag-file {
     height: 72px;
     min-height: unset;
     padding: unset;
  }

  .account-fullname {
   display: flex;
   justify-content: center;
   font-size: 24px;
   font-weight: 600;
   line-height: 32px;
 }

  .account-status {
   display: flex;
   justify-content: center;
   margin-bottom: 24px;
   margin-top: 20px;
  }
}
