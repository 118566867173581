.ticket-manage-page {
  // .filter-btn {
  //   border-radius: 8px;
  //   background-color: #fff;
  //   border: 1px solid #e8e8e8;
  //   margin-right: 16px;
  // }
  .filter-tag-block{
    display: flex;
    margin-top: 16px;
    gap: 16px;
  }

  .filter-tag-item{
    border-radius: 40px;
    background: var(--light-background-secondary, #F6F6F6);
    /* Light/Drop Shadow/1 */
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    padding: 4px 14px;
    align-items: center;
    gap: 10px;
    color: var(--light-grayscale-200, #585757);
    text-align: center;

    /* Paragraph / P2 - 16 */
    // font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border: 1px solid transparent;
    cursor: pointer;
  }



  .count-tag{
    padding: 0px 8px;
    border-radius: 96px;
    background: var(--light-grayscale-300, #969696);
    color: var(--White, #FFF);
    text-align: center;

    /* Caps/Caps 3 - 10 Bold */
    // font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 160% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .filter-tag-item.active{
    border-radius: 40px;
    border: 1px solid var(--light-green-base, #00BA34);
    background: var(--light-background-primary, #FFF);
    color: var(--light-green-base, #00BA34);

    /* Light/Drop Shadow/1 */
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  }

  .filter-tag-item.active .count-tag{
    color: var(--White, #FFF);
    background: var(--light-green-base, #00BA34);
  }
}

.ticket-drawer {
  .ant-drawer-body {
    padding-top: 0;
  }
  .ticket-info-detail {
    .img-body {
      img {
        width: 231px;
        height: 130px;
        object-fit: contain;
      }
      .download-img {
        width: 28px;
        height: 28px;
        position: absolute;
        bottom: 12px;
        right: 12px;
        cursor: pointer;
        background: transparent;
      }
      .close-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 4px;
        right: 12px;
        cursor: pointer;
        background: transparent;
      }
    }
  }
  .reason {
    padding: 16px;
    border-radius: 8px;
    background: #F6F6F6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #585757;
    margin-top: 24px;
  }

  .ant-tabs-nav {
    margin-bottom: 24px;
  }

  .ticket-info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .ticket-code-title {
    color: #1c1c1c;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .ticket-code {
    color: var(--light-green-base, #00ba34);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    margin-left: 12px;
  }

  .ticket-detail-panel {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .ticket-detail-item {
    margin-bottom: 0;
  }
}
