.drawer-detail-export {
  .detail-info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .detail-code-title {
      color: #1c1c1c;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
    .export-code {
      color: var(--light-green-base, #00ba34);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-left: 12px;
    }
  }
}
