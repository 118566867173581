.cross-check-manage {
    height: 100%;
    display: flex;
    flex-direction: column;
    .breadcrumb-bc {
        display: flex;
        justify-content: space-between;
    }
    .main-block {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .filter-upload-section {
            flex-grow: unset;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            padding: 24px;
            .filter-section {
                display: flex;
                gap: 16px;
            }
            .upload-section {
                display: flex;
                gap: 16px;
                .upload-block {
                    flex: 1 0 0;
                    .drag-file {
                        min-height: 40px !important;
                    }
                    .file-uploaded {
                        background: #F6F6F6;
                        min-height: 105px;
                        border-radius: 8px;
                        border: 1px dashed #E8E8E8;
                        padding: 32px;
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        .file-information {
                            display: flex;
                            flex: 1;
                            gap: 16px;
                            .file-icon {
                                display: flex;
                                align-items: center;
                            }
                            .file-name {
                                // white-space: nowrap;
                                // overflow: hidden;
                                // text-overflow: ellipsis;
                            }
                        }
                        .btn-remove-file {
                            cursor: pointer;
                        }
                    }
                }
                .btn-audit {
                    width: 350px;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
        .result-section {
            flex-grow: unset;
            min-height: 200px;
            padding: 16px;
            .result-table {
                width: 100%;
                .matched-row {
                    // background: #231d33;
                }
                .recored-row {
                    background: #fef4e8;
                }
                .mismatched-row {
                    background: #ffebec;
                }
                .information-row {
                    background: #f6f6f6;
                }
            }
        }
    }
}