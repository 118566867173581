.inventory-manage-page {
  .inventory-content {
    .ant-tabs .ant-tabs-tab {
      padding: 12px !important;
      margin-top: 15px !important;
    }
    .import-content {
      background: #ffffff;
      padding: 36px;
    }
    .export-content {
      background: #ffffff;
      padding: 36px;
    }
  }
}
