.flash-sale-page {

  .child-nav {
    display: flex;
    column-gap: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .child-nav-item {
    padding: 8px 12px;
    color: var(--light-grayscale-200, #585757);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    /* 150% */
    &:hover {
      text-decoration: none;
    }
  }

  .child-nav-active {
    border-radius: 8px;
    background: var(--light-background-green,
        linear-gradient(0deg,
          rgba(0, 186, 52, 0.1) 0%,
          rgba(0, 186, 52, 0.1) 100%),
        #fff);
    color: #00ba34;
  }

  .flash-sale__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .flash-sale__item {
    display: flex;
    align-items: center;
    padding: 24px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 3px 0px #0000001A;
    gap: 24px;
    cursor: pointer;
  }

  .flash-sale__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .flash-sale__name {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #1C1C1C;
    margin-bottom: 0;

  }

  .flash-sale__details {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  .flash-sale__code {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #585757;

  }

  .flash-sale__date {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #585757;
    min-width: 200px;
    display: flex;
    align-items: center;
    gap: 8px
  }

  .flash-sale__status {}

  .flash-sale__action {
    min-width: 150px;
    font-size: 16px;
    border-radius: 8px;
  }

  .flash-sale__more {
    cursor: pointer
  }

  .flash-sale_pagination {
    display: flex;
    justify-content: center;
  }
}


.drawer-create-flash-sale {
  .input-discount input {
    text-align: center;
  }

  .product-flash-sale-table .ant-table-row-expand-icon-cell {
    display: none
  }

  .flash-sale-detail-code {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 24px;
  }
}


.modal-select-product-flash-sale {
  .list-product-table {
    margin-top: 24px;
  }
}


.modal-change-status-flash-sale {
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #1C1C1C;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #585757;
  }
}