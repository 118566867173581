.menu-item-block {
   background-color: #fff;
   padding-top: 28px;
   padding-right: 24px;
   padding-left: 20px;
   padding-bottom: 12px;
   margin-bottom: 24px;
}

.text-megamenu {
   max-width: 250px;
   overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
}
