.login-page {
  .login-bg {
    background: #f7f7f7;
    min-height: 100vh;
  }

  .popup-login {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }

  .left-content {
    img {
      width: 720px;
      height: 900px;
    }
  }

  .right-content {
    padding-top: 95px;
    background-color: #fff;
    width: 720px;
  }

  .logo-header {
    padding-left: 88px;
    margin-bottom: 22px;

    img {
      width: 239px;
      height: 109px;
    }
  }

  .form-login {
    margin-left: 112px;
    width: 376px;

    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      color: #1c1c1c;
      margin-bottom: 12px;
    }
  }

  .sub-des {
    font-size: 16px;
    line-height: 24px;
    color: #585757;
    margin-bottom: 36px;
  }

  .input-login-item {
    .label-input {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #585757;
      margin-bottom: 8px;
    }

    margin-bottom: 24px;
  }

  .form-input {
    //padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    width: 100%;
  }

  // .checkbox {
  // background: #FFFFFF;
  // border: 1px solid #E8E8E8;
  // box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  // border-radius: 4px;
  // margin-right: 6px;
  // }

  .remember-me {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #585757;
  }

  .forget-password {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0085ff;
    cursor: pointer;
    text-decoration: none;
  }

  .btn-login {
    background: #00ba34;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06),
      0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 36px;
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }

  @media (max-width: 1440px) {
    .login-bg {
      width: 100%;
      background: #fff;
    }

    .popup-login {
      width: 100%;
    }

    .left-content {
      width: 50%;
      flex-shrink: 0;
    }

    .left-content img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .right-content {}

    .logo-header {
      padding-left: 8px;
    }

    .form-login {
      padding: 0 16px;
      width: unset;
      margin-left: unset;
    }
  }

  @media (max-width: 992px) {
    .left-content {
      display: none;
    }

    .right-content {
      padding-top: unset;
      margin-left: auto;
      margin-right: auto;
    }

    .logo-header {
      padding-left: 0;
    }
  }


  @media (max-width: 720px) {

    .logo-header {
      padding-left: 45px;
    }

    .form-login {
      padding: 0 60px;
    }
  }

  @media (max-width: 576px) {

    .popup-login {
      height: 100%;
      padding-top: 60px;
    }


    .logo-header {
      padding-left: 12px;

      img {
        width: 140px;
        //height: 52px;
        height: auto;
      }
    }

    .form-login {
      padding: 0 24px;

      h1 {
        color: var(--light-grayscale-100, #1C1C1C);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        /* 133.333% */
        margin-bottom: 8px;
      }
    }

    .sub-des {
      color: var(--light-grayscale-200, #585757);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      margin-bottom: 32px;
    }

    .forget-password {
      color: var(--light-blue-base, #0085FF);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 142.857% */
    }

    .btn-login {
      margin-top: 32px;
      color: var(--white, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      padding: 8px 12px;
    }
  }

}





.modal-change-password {

  // .ant-modal {
  //   font-family: 'Roboto', sans-serif !important;
  // }
  .modal-content {
    border: none;
  }

  .mcp-header {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #1c1c1c;
  }

  .mcp-sub-header {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #585757;
    margin-top: 8px;
    text-align: center;
    .fw-bold {
      font-weight: 700;
    }
    .counter {
      font-size: 16px;
      font-weight: 600;
      color: #00ba34;
      .ant-statistic .ant-statistic-content {
        font-weight: 600;
        color: #00ba34;
        font-size: 16px;
      }
    }

    .disabled-counter {
      cursor: not-allowed !important;
      pointer-events: none;
      opacity: 0.35;
    }
  }

  .resent-coundown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .resent-otp {

  }

  .mcp-form {
    margin-top: 28px;
  }

  .text-checklist {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #585757;
  }

  .otp-container {
    gap: 12px;
    margin-top: 30px;
    input {
      flex: 1;
      border-radius: 8px;
      background: rgba(232, 232, 232, 1);
      border: none;
      height: 68px;
      font-size: 20px;
    }
  }

  .cp-checklist {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 8px;
    padding-left: 28px;

    &.remove-stype {
      list-style: none;
      padding-left: 0px;

      li {
        display: flex;
        align-items: center;
      }
    }

    li {
      margin-bottom: 8px;

      svg {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .btn-update-cont {
    margin-top: 24px;
  }

  .btn-update {
    border-radius: 8px;
    background: #00ba34;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
      0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #fff;
  }
}