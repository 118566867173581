.side-bar::-webkit-scrollbar {
  border-radius: 0;
  width: 4px;
}
.side-bar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(22, 24, 35, .06);
}
.side-bar::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

.side-bar {
  background: #ffffff;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.06);
  min-width: 300px;
  height: calc(100vh);
  z-index: 100;
  position: fixed;
  will-change: left;
  transition: all 0.3s ease-in-out;
  overscroll-behavior: contain;
}

.sidebar-active{
  //left: 0;
  transform: translateX(0);
  overflow-y: auto;
}


.side-bar-inactive{
  //left: -100%;
  transform: translateX(-100%);
}



.logo {
  text-align: center;
  min-width: 275px;
  padding-right: 24px;
  img {
    height: 50px;
  }
}

.nav-list-all {
  // max-height: calc(100vh - 100px);
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 150px;
  .nav-list {
    margin-left: 10px;
    margin-top: 15px;
  }
}

// /* width */
// .nav-list::-webkit-scrollbar {
//   width: 2px;
// }

// /* Track */
// .nav-list::-webkit-scrollbar-track {
//   background: #f1f1f1;
//   border-radius: 10px;
// }

// /* Handle */
// .nav-list::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// .nav-list::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.nav-item {
  padding: 0 12px;
  margin-bottom: 12px;
}
.nav-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  color: #585757;
  padding: 10px 12px;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
  }
  &.active {
    background: linear-gradient(
        0deg,
        rgba(0, 186, 52, 0.1),
        rgba(0, 186, 52, 0.1)
      ),
      #ffffff;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1c1c1c;
    .nav-icon {
      filter: invert(21%) sepia(99%) saturate(6246%) hue-rotate(89deg)
        brightness(107%) contrast(107%);
    }
  }
}

.nav-left-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  color: #585757;
  padding: 10px 12px;
  margin-left: 12px;
  border-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
  }
  &.active {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1c1c1c;
  }
}

.nav-icon {
  margin-right: 12px;
}

.nav-dd-icon {
  margin-left: 15px;
  &.active {
    transform: rotateZ(180deg);
  }
}

.nav-group {
  margin-top: 16px;
}

.nav-group-name {
  padding: 0px 24px;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #969696;
}


@media (max-width: 1023px) {
  .side-bar{
    position: absolute;
    z-index: 102;
  }
  .logo {
    margin-top: 20px;
  }
}