.discount-card-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-tabs-nav {
        margin: 0 !important;
    }

    .ant-tabs-tab {
        border: none !important;
        background: #f7f7f7 !important;
        padding: 11px 30px !important;
    }

    .ant-tabs-tab-active {
        background: white !important;
    }

    .ant-tabs-tab-btn {
        color: black !important;
    }

    .card-discount-body {
        background: white;
        min-height: 500px;
        padding: 0 8px;

        .setting-card-body-header {
            padding: 0 16px;
            color: #1C1C1C;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        .title-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 28px;
            .title {
                padding: 0 16px;
                color: #1C1C1C;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .filter-section {
            padding: 0 16px;
            margin-top: 18px;
        }

        .setting-card-body {
            padding: 28px 26px 0;
        }

        input {
            text-align: right;
        }
    }
}
