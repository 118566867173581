$header-height: 72px;

iframe {
  display: none !important;
}

.noti-dd {
  width: 352px;
  max-height: 664px;
}

.noti-header {
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 600;
  cursor: default;
}

.noti-body {
  min-height: 100px;
}

.noti-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.noti-item {
  display: flex;
  padding: 12px 36px 12px 16px;
  align-items: center;
  gap: 12px;
}

.noti-bell {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  background-color: #e8e8e8;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
  }
}

.noti-title {
  font-size: 14px;
  font-weight: 600;
  color: #1c1c1c;
  margin-bottom: 2px;
}

.noti-desc {
  font-size: 14px;
  color: #585757;
  margin-bottom: 4px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noti-time {
  font-weight: 400;
  font-size: 12px;
  color: #969696;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  height: $header-height;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.button-header {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.email-header {
  margin-right: 24px;
  cursor: pointer;
}

.header-avt {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1c;
  position: relative;
  cursor: pointer;
}

.avt {
  margin-right: 8px;
}

.avatar-dd {
  margin-left: 11px;
}

.account-dd {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.1), 0px 9px 46px rgba(0, 0, 0, 0.06),
    0px 24px 38px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  top: 55px;
  right: 0;
  padding: 16px 0px;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
  z-index: 9999;
}

.account-dd-item {
  padding: 14px 16px;
  width: 256px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img,
  svg {
    margin-right: 12px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.account-dd-item-logout {
  color: #e92c2c;
  border-top: 1px solid #e8e8e8;
}

.placeholder-header {
  width: 100%;
  height: 72px;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.40 !important;
  cursor: not-allowed;
}

@media (max-width: 1023px) {
  header {
    background-color: #f6f6f6;
    position: relative;
  }

  .placeholder-header {
    display: none;
  }
}

@media (max-width: 576px) {
  header {
    height: 44px;
    padding: 6px 16px;
    //background: #fff;
  }

  .button-header {
    width: 24px;
    height: 24px;
  }

  .avt {
    width: 32px;
    height: 32px;
  }

  .header-avt-text {
    display: none;
  }

  .avatar-dd {
    display: none;
  }
}