.external-account-page {
  .filter-btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    padding: 0px 12px;

    p {
      margin-left: 8px !important;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.create-external-account {
  .ant-space {
    display: flex;
    gap: 24px !important;

    .ant-space-item {
      width: 100%;
    }

    .ant-space-item:last-child {
      width: 50px;
      position: relative;

      span {
        position: absolute;
        top: 27px;
      }
    }
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    color: #00BA34;
  }

  .account-fullname {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

   .account-status {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 20px;
   }

  .loading-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-green {
    background-color: #00BA34;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 8px;

    &:hover {
      background-color: #00BA34;
      color: #fff !important;
      opacity: 0.8;
    }
  }

  .btn-red {
    background-color: #E92C2C;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      background-color: #E92C2C;
      color: #fff !important;
      opacity: 0.8;
    }
  }

  .manage-device-section {
    .no-device-list {
      padding-top: 32px;
      display: flex;
      justify-content: center;
      color: #969696;
      font-size: 14px;
      line-height: 20px;
    }

    .add-device-btn {
      width: 100%;
      justify-content: center;
      background-color: #00BA341A;
      color: #00BA34;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #00BA341A;
      outline: none;


      &:hover {
        background-color: #00BA341A;
        color: #00BA34;
        border: 1px solid #00BA341A;
      }
    }
  }

}

.modal-external-account {
  .btn-green {
    background-color: #00BA34;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 8px;

    &:hover {
      background-color: #00BA34;
      opacity: 0.8;
    }
  }

  .btn-red {
    background-color: #E92C2C;
    border-radius: 8px;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;

    &:hover {
      background-color: #E92C2C;
      opacity: 0.8;
    }
  }


}
