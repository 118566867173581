.activate-insurance-page {
  min-height: 400px;
  .not-content {
    width: 100%;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--light-background-secondary, #f6f6f6);
    p {
      margin-bottom: 0;
      color: var(--light-grayscale-300, #969696);
      font-weight: 400;
      font-size: 14px;
    }
  }
  .btn-check {
    background: #ffffff;
    color: #00ba34;
    border: 1px solid #00ba34;
    margin-top: 5px;
  }
  .detail-content {
    display: flex;
    align-items: center;
    gap: 24px;
    background: var(--light-background-secondary, #f6f6f6);
    padding: 16px;
    img {
      width: 144px;
      height: 144px;
      object-fit: contain;
    }
    .detail-info {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 24px;
      .detail-info-left {
        p {
          font-size: 16px;
          font-weight: 600;
          color: var(--light-grayscale-300, #969696);
        }
      }
      .detail-info-right {
        p {
          color: var(--light-grayscale-100, #1c1c1c);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
  .alert-success {
    padding: 16px;
    padding-top: 0;
    background: var(--light-background-secondary, #f6f6f6) !important;
  }
  @media (max-width: 577px) {
    .form-divide {
      display: flex;
      flex-direction: row;
      .btn-check {
        margin-top: 10px;
      }
    }
    .detail-content {
      display: flex;
      flex-direction: column;
      img {
        width: 120px;
        height: 120px;
      }
      .detail-info {
        .detail-info-left {
          p {
            font-size: 14px !important;
          }
        }
        .detail-info-right {
          p {
            font-size: 14px !important;
          }
        }
      }
    }
    .success-content {
      .form-divide {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
