.status-detail-available {
  .ant-select-selector {
    background: linear-gradient(0deg, rgba(0, 186, 52, 0.1), rgba(0, 186, 52, 0.1)), #ffffff;
    color: #00BA34 !important;
    border: 1px solid #00BA34 !important;
  }
}

.status-detail-suspend {
  .ant-select-selector {
    background: linear-gradient(0deg, rgba(255, 59, 59, 0.1), rgba(255, 59, 59, 0.1)), #ffffff;
    color: #e92c2c !important;
    border: 1px solid #E92C2C !important;
  }
}

.status-detail-soldout {
  .ant-select-selector {
    background: #f7f7f7;
    color: #969696 !important;
    border: 1px solid #969696 !important;
  }
}