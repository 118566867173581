.activity-item {
  margin-top: 8px;
}

.activity-time {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #969696;
  text-align: center;
}

.activity-update {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.activity-avt {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1px solid #e8e8e8;
}

.activity-fullname {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #1c1c1c;
  margin-right: 12px;
}

.activity-update-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #1c1c1c;
}

.activity-status {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  align-items: center;
}

.arrow-forward-item {
  margin: 0 16px;
}

.assignee_name {
  color: #0085FF;

}
