.product-list-page {
  .main-content {
    .filter-btn {
      display: flex;
      align-items: center;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid #e8e8e8;
      padding: 0px 12px;

      p {
        margin-left: 8px !important;
        margin: 0;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
      text-align: center !important;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      text-align: center !important;
    }
  }
  @media (max-width: 1023px) {
    .main-table {
      overflow-x: scroll;
      .table {
        min-width: 1000px;
      }
    }
  }

  @media (min-width: 501px) and (max-width: 577px) {
    .breadcrumb-bc {
      .btn-inport-export {
        padding: 0px 6px;
        font-size: 12px;
      }
      .btn-action-add {
        padding: 0px 6px;
        font-size: 12px;
      }
    }
    .main-content {
      .filter-content {
        padding: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .breadcrumb-bc {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: #1c1c1c;
      margin-bottom: 16px;
      .btn-inport-export {
        padding: 0px 6px;
        font-size: 10px;
      }
      .btn-action-add {
        padding: 0px 6px;
        font-size: 10px;
      }
    }
    .main-content {
      .filter-content {
        padding: 16px;
      }
    }
  }
}
