.extend-package-page {

  .table-extend-list{
    margin-top: 24px;
  }

  .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
    border: none;
    background: rgba(0, 0, 0, 0);
  }

  .ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
    background: #fff;

    /* Heading/H5 - 16 */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .ant-tabs .ant-tabs-tab-btn{
    color: var(--light-grayscale-100, #1C1C1C);
    font-weight: 600;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: var(--light-grayscale-100, #1C1C1C);
  }

  .ant-tabs-top >.ant-tabs-nav{
    margin-bottom: 0;
  }

  .ant-tabs .ant-tabs-content-holder {
    padding: 16px;
    background: #fff;
  }
}



.drawer-extend-package{
  .extend-info-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .extend-info-body{
    margin-top: 24px;
  }

  .extend-info{
    display: flex;
    /* justify-content: space-between; */
    gap: 16px;
    align-items: center;
  }

  .extend-phone-provider{
    font-size: 24px;
  }

  .extend-phone-number{
    color: var(--light-green-base, #00BA34);

    /* Heading/H3 - 24 */
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .extend-action{
    display: flex;
    gap: 16px;
  }

  .ticket-detail-right {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}


.modal-extend-package{
  .package-title{
    color: var(--light-grayscale-200, #585757);

    /* Heading/H4 - 20 */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    margin-bottom: 12px;
  }

  .ticket-detail-item{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: var(--light-grayscale-300, #969696);

    /* Heading/H6 - 14 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }

  .ticket-detail-left{
    width: 176px;
  }

  .ticket-detail-right{
  }



  .package-selector{
    display: flex;
    gap: 16px;
  }

  .package-selector-header{
    color: var(--light-grayscale-300, #969696);

    /* ST1_14SB_20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    height: 52px;
    align-items: center;
  }

  .package-selector-left{
    flex: 1;
  }

  .package-selector-right{
    flex: 1;
  }

  .package-description{
    height: 240px;
    overflow-y: scroll;
    margin-top: 12px;
  }


  //Custom scrollbar
  .package-description::-webkit-scrollbar {
    border-radius: 0;
    width: 4px;
  }
  .package-description::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, .06);
  }
  .package-description::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
  }

  //Custom scrollbar


  .package-price-block{
    display: flex;
    background: var(--light-background-secondary, #F6F6F6);
    height: 52px;
    align-items: center;
  }

  .package-price-text{
    flex: 7;
    text-align: center;
    color: var(--light-grayscale-200, #585757);

    /* B2_14R_20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .package-price{
    flex: 3;
    text-align: right;
    padding: 16px;
    color: var(--light-grayscale-200, #585757);

    /* Heading/H6 - 14 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }


  .new-package-block{
    height: 290px;
  }


}
