.export-manage-page {
  .filter-btn {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    padding: 0px 12px;

    p {
      margin-left: 8px !important;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
