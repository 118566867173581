.white-list-page {
  .header-block {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    margin: 0 30px;

    .header-text {
      font-size: 20px;
      font-weight: 600;
      color: #1C1C1C;
    }
  }

  .modal-add-content {
    .search-box {
      width: 100%;
    }
  }
}