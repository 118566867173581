.topup-order-manage-page {
  .topup-order-content {
    .ant-tabs .ant-tabs-tab {
      padding: 10px !important;
      margin: 0 10px !important;
    }
    .body-tabs {
      .statistic-section {
        background: #ffffff;
        margin-bottom: 16px;
        padding: 16px;
        display: flex;
        padding-right: 150px;
        justify-content: space-between;
        .statistic-item {
          .item-key {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: #969696;
          }
          .item-value {
            color: #1c1c1c;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
          }
        }
      }
      .list-section {
        padding: 36px;
        background: #ffffff;
        .filter-btn {
          display: flex;
          align-items: center;
          border-radius: 8px;
          background-color: #fff;
          border: 1px solid #e8e8e8;
          padding: 0px 12px;

          p {
            margin-left: 8px !important;
            margin: 0;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }
  }
}


.modal-confirm-topup-missing {
  .anticon-exclamation-circle {
    display: none;
  }

  .ant-modal-confirm-title {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    margin-bottom: 0;
  }
}
